
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { priceRulesEl } from "@/utils/verify";
import FileUpload from "@/components/scope/file-upload.vue";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
const base = namespace("base");
const marketing = namespace("marketing");
@Component({
  components: { FileUpload }
})
export default class MiniProgramSetting extends Vue {
  @base.Action getDictionaryList;
  @marketing.Action queryMiniProgramQrByStore;
  @marketing.Action updateStoreInfoByWeChat;
  @marketing.Action queryStoreInfoByWeChat;

  settingForm = {
    id: "defalut",
    closingTime: "",
    openingTime: "",
    servicePhone: "",
    storePhoto: [],
    openReservation: 1,
    businessHours: [dayjs().hour(9).startOf("h").toDate(), dayjs().hour(17).startOf("h").toDate()]
  };
  storeQrUrl: string = "";
  settingFormRules = {
    storePhoto: {
      type: "array",
      required: true,
      message: t("v210831.please-upload-store-img"),
      trigger: "change"
    },
    businessHours: {
      type: "array",
      required: true,
      message: t("v210831.please-select-business"),
      trigger: "change"
    },
    servicePhone: { required: true, message: t("v210831.please-enter-external"), trigger: "blur" },
    openReservation: { required: true, message: t("v210831.please-select-whether-or-subscribe"), trigger: "blur" }
  };
  created() {
    this.init();
  }
  photoDefaultList = [];
  init() {
    this.queryMiniProgramQrByStore().then(data => {
      this.storeQrUrl = data.data;
    });
    this.queryStoreInfoByWeChat().then(data => {
      if (data.data.openingTime) {
        const openingHour = Number(data.data.openingTime.substr(0, 2));
        const openingMinute = Number(data.data.openingTime.substr(2, 2));
        const closingHour = Number(data.data.closingTime.substr(0, 2));
        const closingMinute = Number(data.data.closingTime.substr(2, 2));
        data.data.businessHours = [
          dayjs().hour(openingHour).minute(openingMinute).startOf("m"),
          dayjs().hour(closingHour).minute(closingMinute).startOf("m")
        ];
      }
      Object.assign(this.settingForm, data.data);
      if (data.data.storePhoto.length) {
        this.photoDefaultList = data.data.storePhoto.map(item => ({ url: item }));
      }
    });
  }
  mounted() {}
  submitForm() {
    (this.$refs.settingForm as any).validate(valid => {
      if (valid) {
        this.settingForm.openingTime = dayjs(this.settingForm.businessHours[0]).format("HHmm");
        this.settingForm.closingTime = dayjs(this.settingForm.businessHours[1]).format("HHmm");
        this.updateStoreInfoByWeChat(this.settingForm).then(data => {
          this.$message.success(t("v210831.saved-successfully"));
        });
      }
    });
  }
  handleDownloadQr() {
    this.queryMiniProgramQrByStore().then(data => {
      this.storeQrUrl = data.data;
      window.open(this.storeQrUrl);
    });
  }
  handleBannerUploadSuccess() {
    (this.$refs.settingForm as any).validateField("storePhoto");
  }
}
